<template>
  <el-dialog
    :title="title"
    :visible.sync="showDialog"
    :close-on-click-modal="false"
    append-to-body
    @close="closedFn"
    width="680px"
    top="5vh"
  >
    <div class="container">
      <div class="share-box" ref="shareBox">
        <div class="img_box">
          <img :src="company.emblem || defaultLogo" />
        </div>
        <div class="title m-t-10 text-center">{{ company.storeName }}</div>
        <div class="desc m-t-10">{{ desc }}</div>
        <div class="line m-tb-20"></div>
        <div class="text-999 font-size-12 m-b-20">扫一扫或长按识别</div>
        <div class="m-b-20">
          <div class="flex flex-center">
            <div class="flex1 flex-column flex-center">
              <img :src="shareUrl" class="qrcode-img" />
              <div class="font-size-12 m-t-10">关注公众号</div>
              <div class="font-size-12">及时掌握订单动态</div>
            </div>
            <div class="m-l-30 flex1 flex-column flex-center">
              <Qrcode :link="qrcodeLink" :codeW="100" />
              <div class="font-size-12 m-t-10">进入移动端商城</div>
              <div class="font-size-12">随时随地查件下单</div>
            </div>
          </div>
        </div>
        <div class="text-999 font-size-12">东方思锐软件提供IT技术支持</div>
      </div>
      <div class="btns flex flex-row-center">
        <el-button @click="copyImageFn" type="text">图片复制</el-button>
        <el-divider direction="vertical" class="m-lr-20"></el-divider>
        <el-button @click="copyLink" type="text">链接复制</el-button>
      </div>
      <div class="text-left m-lr-20 text-666 font-size-12 m-b-20">
        提示：
        <div>
          1、可进行图片复制、链接复制，通过微信或第三方程序分享给对应的员工。
        </div>
        <div>
          2、分享链接将会自动识别登录的商家端（例：链接在电脑浏览器登录为商家端（PC），链接在手机登录识别为商家端（H5））。
        </div>
        <div>
          3、员工识别“图片复制”分享的公众号二维码关注成功后进入在线商城或点击“链接复制”分享的链接，员工首次自动登陆进入订货系统，首次登陆为保障账号安全，账号需要设置新密码。
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { mapState } from "vuex";
import html2canvas from "html2canvas";
import { staffsShareById } from "@/api/staffs";
import Qrcode from "@/components/Qrcode/index.vue";
import copyImage from "@/utils/copyImage";

export default {
  name: "AccountShareBox",
  components: {
    Qrcode
  },
  props: {
    curRow: Object
  },
  data() {
    return {
      title: "账号分享",
      showDialog: false,
      desc: "优质好货，快来逛逛~",
      defaultLogo: require("@/assets/img/default-logo.png"),
      siruiOffiaccountIcon: require("@/assets/img/sirui_offiaccount.png"), // 思锐公众号
      shareUrl: "",
      qrcodeLink: ""
    };
  },

  computed: {
    ...mapState("companyInfo", {
      company: state => {
        return state.company;
      }
    })
  },
  methods: {
    show() {
      this.showDialog = true;
      setTimeout(() => {
        this.getCopyUrl();
      }, 100);
    },
    //获取分享链接
    getCopyUrl() {
      const params = {
        id: this.curRow.id
      };
      staffsShareById(params)
        .then(res => {
          if (res && res.share_url) {
            this.shareUrl = window.tool.imageCrossReplace(res.share_url);
          } else {
            this.shareUrl = "";
          }
          if (res && res.url) {
            this.qrcodeLink = res.url;
          } else {
            this.qrcodeLink = "";
          }
        })
        .catch(err => {
          this.$message.error(err?.message);
        });
    },
    closedFn() {
      this.showDialog = false;
    },
    copyImageFn() {
      this.$nextTick(() => {
        html2canvas(this.$refs.shareBox).then(canvas => {
          const base64Url = canvas.toDataURL("image/png");
          copyImage(base64Url)
            .then(() => {
              this.$message.success("图片复制成功，请前往第三方程序粘贴分享吧！");
            })
            .catch(() => {
              this.$message.error("复制失败");
            });
        });
      });
    },
    copyLink() {
      const text = `尊敬的用户（${this.company.storeName}）诚邀您进入系统，首次登录修改密码后请妥善保管（登录用户名与密码）：\n链接入口（首次进入自动登录）\n${this.qrcodeLink}`;
      this.$copyText(text).then(
        () => {
          this.$message.success("登录地址复制成功，请前往第三方程序粘贴分享吧！");
        },
        () => {
          this.$message.error("复制失败");
        }
      );
    }
  }
};
</script>

<style lang="less" scoped>
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  .share-box {
    width: 325px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    box-sizing: border-box;
    background: #ffffff;
    box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05);
    border-radius: 2px;
    .img_box {
      width: 62px;
      height: 62px;

      img {
        width: 100%;
        border-radius: 2px;
      }
    }
    .qrcode-img {
      width: 120px;
      height: 120px;
      // padding: 5px;
      box-shadow: 0 0px 10px rgba(0, 0, 0, 0.1);
      border-radius: 8px;
    }
    .title {
      font-family: PingFangSC-Medium;
      font-size: 18px;
      color: #333333;
      line-height: 28px;
      font-weight: 500;
    }
    .desc {
      font-family: PingFangSC-Regular;
      font-size: 14px;
      color: #999999;
      line-height: 22px;
      font-weight: 400;
    }
    .line {
      height: 1px;
      width: 100%;
      border-bottom: 1px dashed @border-color-base;
    }
  }
  .btns {
    margin-top: 10px;
    /deep/.el-divider {
      background-color: @color-link;
    }
  }
}
</style>
