<template>
  <el-dialog
    :title="title"
    v-bind="$attrs"
    v-on="$listeners"
    :close-on-click-modal="false"
    append-to-body
    @close="close"
    width="800px"
  >
    <div v-loading="loading">
      <div v-for="(item, i) in permissionsList" :key="i">
        <DetailTitle :titleName="item.name" />
        <div class="p-lr-10 p-tb-10">
          <el-checkbox v-model="items.isSelect" v-for="(items, j) in item.list" :key="j" class="m-tb-4">
            {{ items.name }}
          </el-checkbox>
        </div>
      </div>
    </div>
    <div slot="footer" class="text-right">
      <el-button plain size="small" @click="close">取消</el-button>
      <el-button type="primary" size="small" @click="confirm" v-loading="loading">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import DetailTitle from "@/components/DetailTitle";
import { getPermissions, setPermissions } from "@/api/permissions";

export default {
  name: "StaffPermSettingBox",
  components: {
    DetailTitle
  },
  props: {
    curRow: {
      type: Object
    }
  },
  data() {
    return {
      title: "员工权限设置",
      loading: false,
      permissionsList: [
        {
          name: "购物车",
          fields: "carts",
          list: [
            {
              name: "基础权限（查看购物车、添加到购物车、修改/删除自己添加到购物车的商品）",
              isSelect: false,
              fields: "selfBase"
            },
            {
              name: "提交自己添加到购物车的商品",
              isSelect: false,
              fields: "selfSubmit"
            },
            {
              name: "查看其他人添加到购物车的商品",
              isSelect: false,
              fields: "othersRead"
            },
            {
              name: "修改其他人添加到购物车的商品",
              isSelect: false,
              fields: "othersEdit"
            },
            {
              name: "删除其他人添加到购物车的商品",
              isSelect: false,
              fields: "othersDelete"
            },
            {
              name: "提交其他人添加到购物车的商品",
              isSelect: false,
              fields: "othersSubmit"
            }
          ]
        },
        {
          name: "订单",
          fields: "orders",
          list: [
            {
              name: "基础权限（查看自己创建的订单）",
              isSelect: false,
              fields: "selfBase"
            },
            {
              name: "取消自己创建的订单",
              isSelect: false,
              fields: "selfCancel"
            },
            {
              name: "查看其他人创建的订单",
              isSelect: false,
              fields: "othersRead"
            },
            {
              name: "取消其他人创建的订单",
              isSelect: false,
              fields: "othersCancel"
            }
          ]
        },
        {
          name: "其他权限",
          fields: "others",
          list: [
            {
              name: "对账信息",
              isSelect: false,
              fields: "statement"
            },
            {
              name: "企业资料",
              isSelect: false,
              fields: "company"
            },
            {
              name: "员工管理",
              isSelect: false,
              fields: "staffs"
            }
          ]
        }
      ],
      permissions: {
        carts: {
          selfBase: false,
          selfSubmit: false,
          othersRead: false,
          othersEdit: false,
          othersDelete: false,
          othersSubmit: false
        },
        orders: {
          selfBase: false,
          selfCancel: false,
          othersRead: false,
          othersCancel: false
        },
        others: {
          statement: false,
          company: false,
          staffs: false
        }
      }
    };
  },
  watch: {
    "$attrs.visible": {
      handler: function(val) {
        if (val) {
          this.getPermissions();
        }
      }
    }
  },
  methods: {
    getPermissions() {
      this.loading = true;
      getPermissions(this.curRow.id)
        .then(res => {
          this.loading = false;
          if (res && res.carts) {
            for (const key in res) {
              for (const item of this.permissionsList) {
                if (item.fields === key) {
                  for (const items of item.list) {
                    items.isSelect = res[key][items.fields] || false;
                  }
                }
              }
            }
          } else {
            this.$message.error("获取权限失败");
          }
        })
        .catch(err => {
          this.loading = false;
          this.$message.error(err.message || "获取权限失败");
        });
    },
    close() {
      this.$emit("update:visible", false);
    },
    confirm() {
      // 设置数据
      for (const key in this.permissions) {
        for (const item of this.permissionsList) {
          if (item.fields === key) {
            for (const items of item.list) {
              this.permissions[key][items.fields] = items.isSelect;
            }
          }
        }
      }
      this.loading = true;
      setPermissions(this.curRow.id, this.permissions)
        .then(() => {
          this.loading = false;
          this.close();
          this.$emit("refresh");
          this.$message.success("保存成功");
        })
        .catch(err => {
          this.loading = false;
          this.$message.error(err.message || "保存权限失败");
        });
    }
  }
};
</script>
