<template>
  <div class="client-page staff-page p-tb-16 p-lr-20">
    <el-tabs v-model="activeName" class="tabs-height-full">
      <el-tab-pane :label="staffTabName" name="1">
        <div class="flex-column height-full">
          <div class="p-tb-10 p-lr-10 add-box flex">
            <div v-if="false" class="flex flex-center white-space-nowrap">
              <div>搜索：</div>
              <el-input
                v-model="queryData.keyword"
                size="mini"
                clearable
                placeholder="请输入姓名/用户名/电话"
              ></el-input>
            </div>
            <div v-if="false" class="flex flex-center white-space-nowrap m-l-15">
              <div>账号状态：</div>
              <el-select v-model="queryData.state" size="mini" clearable placeholder="请选择账号状态">
                <el-option v-for="item in stateOptions" :key="item.value" :label="item.value" :value="item.value">
                </el-option>
              </el-select>
            </div>
            <el-button v-if="false" class="m-l-15" type="primary" size="mini" @click="queryFn">查询</el-button>
            <div v-if="false" class="flex1"></div>
            <el-button type="primary" size="mini" @click="addFn">新增员工(Alt+F2)</el-button>
          </div>
          <el-table
            ref="staffsTable"
            class="table-base m-t-10"
            size="small"
            v-loading="loading"
            :data="tableData"
            border
            stripe
            height="400px"
            @selection-change="handleSelectionChange"
          >
            <el-table-column fixed type="selection" align="center" width="55"> </el-table-column>
            <el-table-column fixed type="index" label="序号" align="center" width="55"> </el-table-column>
            <el-table-column prop="name" label="员工姓名" align="center" min-width="150"> </el-table-column>
            <el-table-column prop="role" label="角色" align="center" min-width="100"> </el-table-column>
            <el-table-column prop="account" label="登录用户名" align="center" min-width="150"> </el-table-column>
            <el-table-column prop="phone" label="绑定电话" align="center" min-width="150"> </el-table-column>
            <el-table-column prop="status" label="状态" align="center" min-width="100">
              <template slot-scope="scope">
                <el-switch
                  v-model="scope.row.statusShow"
                  :class="scope.row.statusShow ? 'yes' : 'no'"
                  :disabled="isAdmin(scope.row)"
                  @change="statusChange(scope.row)"
                >
                </el-switch>
              </template>
            </el-table-column>
            <el-table-column fixed="right" label="操作" align="center" width="300">
              <template slot-scope="scope">
                <el-button
                  v-if="!isAdmin(scope.row) && isManager"
                  size="small"
                  type="text"
                  class="text-danger-btn"
                  @click="deleteFn(scope.row)"
                >
                  删除
                </el-button>
                <el-button v-if="isManager" size="small" type="text" @click="resetPwdFn(scope.row)">
                  重置密码
                </el-button>
                <el-button
                  v-if="!isAdmin(scope.row) && isManager"
                  size="small"
                  type="text"
                  @click="setAdminFn(scope.row)"
                >
                  设置管理员
                </el-button>
                <el-button size="small" type="text" @click="openShareFn(scope.row)">
                  分享
                </el-button>
                <el-button
                  v-if="!isAdmin(scope.row) && isManager"
                  size="small"
                  type="text"
                  @click="permSetFn(scope.row)"
                >
                  权限
                </el-button>
              </template>
            </el-table-column>
          </el-table>
          <div class="page_footer">
            <div>
              <span>已选中 {{ selectLen }} 个员工</span>
              <span class="m-lr-8">|</span>
              <span>批量操作</span>
              <el-button size="small" type="danger" class="m-l-8" :disabled="!canDelete" @click="deleteBatchFn">
                删除(F3)
              </el-button>
            </div>
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="queryData.page"
              :page-sizes="[10, 20, 50, 100]"
              :page-size="queryData.pageSize"
              layout="total, prev, pager, next, jumper, sizes"
              :total="queryData.totalSize"
            >
            </el-pagination>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane v-if="false" label="待审核" name="2"></el-tab-pane>
    </el-tabs>
    <!-- 新增员工弹框 -->
    <EditStaffBox :visible.sync="editStaffVisible" @refresh="refreshFn" />
    <!-- 员工权限设置 -->
    <StaffPermSettingBox :visible.sync="staffPermSettingVisible" :curRow="curRow" />
    <!-- 员工分享 -->
    <StaffShareBox ref="staffShare" :curRow="curRow" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import EditStaffBox from "./components/EditStaffBox.vue";
import StaffPermSettingBox from "./components/StaffPermSettingBox.vue";
import StaffShareBox from "./components/StaffShareBox.vue";

import {
  getStaffsList,
  staffsDisable,
  staffsEnable,
  staffsResetPassword,
  staffsDelete,
  staffsChangeManager
} from "@/api/staffs";

export default {
  name: "staffManage", //员工管理
  components: {
    EditStaffBox,
    StaffPermSettingBox,
    StaffShareBox
  },
  data() {
    return {
      activeName: "1",
      keyName: "alt+f2,f3",
      keyScope: "ManageStaffList",
      editStaffVisible: false,
      staffPermSettingVisible: false,
      loading: false,
      tableData: [],
      queryData: {
        keyword: "",
        state: "",
        page: 1,
        totalSize: 0,
        pageSize: 20
      },
      selectionData: [], //已选中
      selectLen: 0, //选中条数
      btnDisabled: true,
      curRow: {},
      isBatch: false, //是否是批量
      stateOptions: [
        { name: "正常", value: "正常" },
        { name: "禁用", value: "禁用" }
      ]
    };
  },
  computed: {
    ...mapState("user", {
      userInfo: state => {
        return state.userInfo;
      }
    }),
    ...mapState("companyInfo", {
      company: state => {
        return state.company;
      }
    }),
    isManager() {
      return this.userInfo.isManager;
    },
    canDelete() {
      if (this.selectionData.length === 1) {
        return !this.selectionData.some(item => item.role === "管理员");
      } else {
        return !!this.selectionData.length;
      }
    },
    staffTabName() {
      return this.queryData.totalSize > 0 ? `员工列表（${this.queryData.totalSize}）` : "员工列表";
    }
  },
  mounted() {
    this.initData();
    setTimeout(() => {
      this.$hotkeys(this.keyName, this.keyScope, this.handleKeyCode);
      this.$hotkeys.setScope(this.keyScope);
    }, 500);
  },
  beforeDestroy() {
    this.$hotkeys.unbind(this.keyName, this.keyScope);
  },
  methods: {
    openShareFn(row) {
      this.curRow = { ...row };
      this.$refs.staffShare.show();
    },
    handleKeyCode(e) {
      switch (e.code) {
        case "F2":
          e.preventDefault();
          e.stopPropagation();
          if (e.altKey) {
            this.addFn();
          }
          break;
        case "F3":
          e.preventDefault();
          e.stopPropagation();
          if (this.canDelete) {
            this.deleteBatchFn();
          }
          break;
      }
    },
    refreshFn() {
      this.queryData.page = 1;
      this.initData();
    },
    statusChange(row) {
      if (!row.statusShow) {
        staffsDisable({ ids: [row.id] })
          .then(() => {
            this.$message.success("禁用员工成功！");
            this.initData();
          })
          .catch(err => {
            this.$message.error(err?.message);
          });
      } else {
        staffsEnable({ ids: [row.id] })
          .then(() => {
            this.$message.success("启用员工成功！");
            this.initData();
          })
          .catch(err => {
            this.$message.error(err?.message);
          });
      }
    },
    //新增员工
    addFn() {
      this.editStaffVisible = true;
    },
    initData() {
      const params = {
        page: this.queryData.page,
        pageSize: this.queryData.pageSize
      };
      this.loading = true;
      getStaffsList(params)
        .then(res => {
          this.loading = false;
          this.tableData = res.row.map(item => ({ ...item, statusShow: item.status === "正常" })) || [];
          this.queryData.totalSize = res.totalSize || 0;
        })
        .catch(err => {
          this.loading = false;
          this.$message.error(err?.message);
        });
    },
    handleSelectionChange(val) {
      this.selectionData = val;
      this.selectLen = val.length;
      this.btnDisabled = !val.length;
    },
    handleSizeChange(val) {
      this.queryData.pageSize = val;
      this.queryData.page = 1;
      this.initData();
    },
    handleCurrentChange(val) {
      this.queryData.page = val;
      this.initData();
    },
    queryFn() {
      this.handleCurrentChange(1);
    },
    deleteBatchFn() {
      this.$confirm(`是否确认删除此员工账号？`, "提示", { type: "warning" })
        .then(() => {
          // 批量删除时，过滤掉管理员
          const selectList = this.selectionData.filter(item => item.role !== "管理员");
          const params = {
            ids: selectList.map(item => item.id)
          };
          this.reqDeleteFn(params);
        })
        .catch(() => {});
    },
    deleteFn(row) {
      this.$confirm(`是否确认删除此员工账号？`, "提示", { type: "warning" })
        .then(() => {
          const params = {
            ids: [row.id]
          };
          this.reqDeleteFn(params);
        })
        .catch(() => {});
    },
    reqDeleteFn(params) {
      staffsDelete(params)
        .then(() => {
          this.$message.success("员工删除成功！");
          this.initData();
        })
        .catch(err => {
          this.$message.error(err?.message);
        });
    },
    resetPwdFn(row) {
      staffsResetPassword({ id: row.id })
        .then(() => {
          this.$message.success("员工密码重置成功，已恢复初始默认密码！");
          this.initData();
        })
        .catch(err => {
          this.$message.error(err?.message);
        });
    },
    setAdminFn(row) {
      staffsChangeManager(row.id)
        .then(() => {
          this.$message.success("设置成功");
          this.handleCurrentChange(1);
          // 获取用户信息
          this.$store.dispatch("user/getUserInfo");
        })
        .catch(err => {
          this.$message.error(err.message || "设置失败！");
        });
    },
    permSetFn(row) {
      this.curRow = { ...row };
      this.staffPermSettingVisible = true;
    },
    isAdmin(row) {
      return ["管理员"].includes(row.role);
    }
  }
};
</script>

<style lang="less" scoped>
.staff-page {
  .add-box {
    background: #eeeeee;
    border-radius: 4px;
  }
  .el-switch {
    &.yes {
      position: relative;
      &::before {
        content: "启用";
        position: absolute;
        left: 6px;
        top: 50%;
        transform: translateY(-50%);
        z-index: 1;
        font-size: 12px;
        color: #ffffff;
      }
    }
    &.no {
      position: relative;
      &::after {
        content: "禁用";
        position: absolute;
        right: 6px;
        top: 50%;
        transform: translateY(-50%);
        z-index: 1;
        font-size: 12px;
        color: #ffffff;
      }
    }
    /deep/.el-switch__core {
      width: 50px !important;
    }
  }
  /deep/.el-button + .el-button {
    margin-left: 10px;
  }
}
</style>
