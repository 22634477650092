<template>
  <el-dialog
    :title="title"
    v-bind="$attrs"
    v-on="$listeners"
    :close-on-click-modal="false"
    append-to-body
    @close="closedFn"
    width="550px"
    top="35vh"
  >
    <div class="p-lr-20">
      <el-form
        :model="dataObj"
        :rules="rules"
        :show-message="false"
        ref="addStaff"
        size="small"
        label-width="100px"
        class="ruleForm m-t-20"
      >
        <el-form-item label="员工姓名" prop="name">
          <el-input v-model.trim="dataObj.name" clearable placeholder="请输入员工姓名" prefix-icon="el-icon-user" />
        </el-form-item>
        <el-form-item label="默认密码" prop="password">
          <el-input
            v-model.trim="dataObj.password"
            type="password"
            clearable
            show-password
            placeholder="请输入默认密码"
            prefix-icon="el-icon-lock"
          />
        </el-form-item>
        <el-form-item label="">
          <div class="text-999">
            提示：
            <div>1、员工添加成功后，登录用户名系统将会自动进行分配</div>
            <div>2、请在员工列表复制链接或二维码分享给员工进行登录系统.</div>
          </div>
        </el-form-item>
      </el-form>
    </div>
    <div slot="footer" class="text-right">
      <el-button plain size="small" @click="closedFn">取消</el-button>
      <el-button type="primary" size="small" @click="onSure">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { createStaffs } from "@/api/staffs";
// 账户密码
const passwordValidate = (rule, value, callback) => {
  // const reg = /^[0-9a-zA-Z]+$/;
  if (value === "") {
    callback(new Error("请输入密码/密码不能为空"));
  } else if (value.length < 8) {
    callback(new Error("请输入8-16位的字母或数字"));
  }
  // else if (!reg.test(value)) {
  //   callback(new Error("请输入8-16位的字母或数字"));
  // }
  else {
    callback();
  }
};

export default {
  name: "EditStaffBox",
  data() {
    return {
      title: "新增员工",
      dataObj: {
        name: "", //员工姓名
        // account: "001@001C",
        password: "" //原密码
      },
      rules: {
        name: [{ required: true, message: "请输入员工姓名", trigger: "blur" }],
        password: [
          { required: true, message: "请输入默认密码8-16字符", trigger: "blur" },
          { validator: passwordValidate, trigger: "blur" }
        ]
      }
    };
  },
  watch: {
    "$attrs.visible": {
      handler(val) {
        if (val) {
          if (this.$refs.addStaff) {
            this.$refs.addStaff.resetFields();
          }
        }
      }
    }
  },
  methods: {
    closedFn() {
      this.$emit("update:visible", false);
    },
    onSure() {
      this.$refs.addStaff.validate((valid, err) => {
        if (valid) {
          //调用新增接口
          const params = {
            ...this.dataObj
          };
          createStaffs(params)
            .then(() => {
              this.$message.success("新增员工成功！");
              this.closedFn();
              this.$emit("refresh");
            })
            .catch(err => {
              this.$message.error(err?.message);
            });
        } else {
          const errTips = err[Object.keys(err)[0]][0].message;
          this.$message.warning({ message: errTips, showClose: true });
        }
      });
    }
  }
};
</script>

<style lang="less" scoped></style>
