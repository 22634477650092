import request from "@/utils/request";

/**
 * 获取员工权限
 */
export function getPermissions(id) {
  return request({
    url: `/mall/accounts/staffs/permissions/${id}`,
    method: "get"
  });
}

/**
 * 更新员工权限
 */
export function setPermissions(id, data) {
  return request({
    url: `/mall/accounts/staffs/permissions/${id}`,
    method: "post",
    data: data
  });
}
