function dataURItoBlob(dataURI) {
  const mimeString = dataURI
    .split(",")[0]
    .split(":")[1]
    .split(";")[0]; // mime类型
  const byteString = atob(dataURI.split(",")[1]); //base64 解码
  const arrayBuffer = new ArrayBuffer(byteString.length); //创建缓冲数组
  const intArray = new Uint8Array(arrayBuffer); //创建视图

  for (let i = 0; i < byteString.length; i++) {
    intArray[i] = byteString.charCodeAt(i);
  }
  return new Blob([intArray], { type: mimeString });
}

function image2blob(url) {
  return new Promise(resolve => {
    // 如果是 data 开头的就使用 DataURI 的解析
    if (url.indexOf("data:") === 0) {
      return resolve(
        // eslint-disable-next-line no-undef
        new ClipboardItem({ "image/png": dataURItoBlob(url) })
      );
    }
    const img = new Image();
    const c = document.createElement("canvas");
    const ctx = c.getContext("2d");

    img.onload = function() {
      c.width = this.naturalWidth;
      c.height = this.naturalHeight;
      ctx.drawImage(this, 0, 0);
      c.toBlob(
        // eslint-disable-next-line no-undef
        blob => resolve(new ClipboardItem({ "image/png": blob })),
        "image/png",
        1
      );
    };
    img.crossOrigin = "";
    img.src = url;
  });
}

function copyImage(url) {
  return new Promise((resolve, reject) => {
    image2blob(url).then(data => {
      navigator.clipboard.write([data]).then(resolve, reject);
    });
  });
}

export default copyImage;
