import request from "@/utils/request";

// 员工列表
export function getStaffsList(params) {
  return request({
    url: "/mall/accounts/staffs/list",
    method: "get",
    params: params
  });
}

// 新增员工
export function createStaffs(params) {
  return request({
    url: "/mall/accounts/staffs/create",
    method: "post",
    data: params
  });
}

// 禁用员工账号
export function staffsDisable(params) {
  return request({
    url: "/mall/accounts/staffs/disable",
    method: "post",
    data: params
  });
}

// 启用员工账号
export function staffsEnable(params) {
  return request({
    url: "/mall/accounts/staffs/enable",
    method: "post",
    data: params
  });
}

// 员工账号重置密码
export function staffsResetPassword(params) {
  return request({
    url: "/mall/accounts/staffs/reset-password",
    method: "post",
    data: params
  });
}

// 删除员工账号
export function staffsDelete(params) {
  return request({
    url: "/mall/accounts/staffs/delete",
    method: "post",
    data: params
  });
}

// 分享员工账号
export function staffsShareById(params) {
  return request({
    url: `/mall/accounts/staffs/${params.id}/share`,
    method: "post"
  });
}

// 修改管理员
export function staffsChangeManager(id) {
  return request({
    url: `/mall/accounts/staffs/change-manager/${id}`,
    method: "post"
  });
}
